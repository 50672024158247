<template>
    <b-modal title="Form APK Versioning" id="form-modal" no-close-on-backdrop no-close-on-esc>
        <b-form-group label="Judul" class="mb-2">
            <b-form-input v-model="form.judul"></b-form-input>
        </b-form-group>
        <b-form-group label="Versi" class="mb-2">
            <b-form-input v-model="form.versi"></b-form-input>
        </b-form-group>
        <b-form-group label="File APK" class="mb-2">
            <input type="file" @change="onChooseFile" />
        </b-form-group>
        <b-form-group label="Tanggal Update" class="mb-2">
            <b-form-input type="date" v-model="form.tgl_update"></b-form-input>
        </b-form-group>
        <b-form-group label="Keterangan (opsional)" class="mb-2">
            <b-form-textarea v-model="form.keterangan"></b-form-textarea>
        </b-form-group>

        <template #modal-footer><b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Simpan</b-button></template>
    </b-modal>
</template>
<script>
    import {BModal, BFormGroup, BFormInput, BFormTextarea, BButton} from 'bootstrap-vue'
    export default {
        components: {
            BModal, BFormGroup, BFormInput, BFormTextarea, BButton
        },
        watch: {
            currentData(val) {
                if(val) {
                    this.form.judul = val.judul
                    this.form.versi = val.versi
                    this.form.tgl_update = val.tgl_update
                    this.form.keterangan = val.keterangan
                }
            }
        },
        computed: {
            currentData() {
                return this.$store.state['apk-versioning'].currentData
            },
            isValidForm() {
                if(!this.form.judul || !this.form.versi || !this.form.tgl_update) {
                    return false
                }

                return true
            }
        },
        data: () => ({
            id: null,
            form: {
                judul: null,
                versi: null,
                tgl_update: null,
                file: null,
                keterangan: null
            }
        }),
        methods: {
            onChooseFile(e) {
                this.form.file = e.target.files[0]
            },  
            submit() {
                if(!this.isValidForm) {
                    this.displayError({
                        message: 'Lengkapi form APK Versioning!'
                    })
                    return false
                }

                const fd = new FormData()
                fd.append('judul', this.form.judul)
                fd.append('versi', this.form.versi)
                fd.append('tgl_update', this.form.tgl_update)
                if(this.form.file) {
                    fd.append('file', this.form.file)
                }

                if(this.form.keterangan) {
                    fd.append('keterangan', this.form.keterangan)
                }
                this.$emit('submit', fd)
                this.resetForm()
            },
            resetForm() {
                this.form.judul = null
                this.form.versi = null
                this.form.tgl_update = null
                this.form.file = null
                this.form.keterangan = null
            }
        },
        created() {
            this.resetForm()
        }
    }
</script>